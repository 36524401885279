<template>
  <div class="containers flex jc ac dc wd_80" :style="styleObject">
    <div class="flex jc ac">
      <img src="@/assets/body/slide.png" class="slide" />
      <div class="font_size">{{ value }}</div>
      <img src="@/assets/body/slide.png" class="slide rotate_180" />
    </div>
    <div v-if="subtitle" class="subtitle">{{ subValue }}</div>
  </div>
</template>

<script>
export default {
  name: "header_title",
  props: {
    title: String,
    subtitle: String,
    height: String,
  },
  data() {
    return {
      value: this.title,
      subValue: this.subtitle,
      styleObject: {
        height: this.height ? this.height : "376px",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.containers {
  // height: 376px;
  width: 100%;
}
.wd_80 {
  width: 80%;
  margin: 0 auto;
}

.subtitle {
  font-size: 16px;
  margin: 10px 0 24px;
  text-align: justify;
  letter-spacing: 6px;
  -webkit-background-clip: text;
}
</style>