<!-- 顶部banner -->
<template>
  <div>
    <div class="top_swippe" v-if="bennrtList.length !== 0">
      <el-carousel height="6.23333rem">
        <el-carousel-item v-for="(item, index) in bennrtList" :key="index">
          <div
            v-if="item.material_type === 1"
            class="bg_img"
            :style="{ background: `url(${item.domain_image}) no-repeat` }"
            @click="imgClick(item)"
          ></div>
          <div 
            v-if="item.material_type === 2" 
            style="text-align: center;">
            <video
              ref="video"
              id="video"
              :src="item.video_url"
              :autoplay="true"
              loop muted="muted"
              webkit-playsinline="true"
              style="height:100%;"
            ></video>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="banner_word_bg flex jc ac dc">
        <div class="font_60">{{ secondMenu }}</div>
        <!-- <div class="font_16">Travelservices</div> -->
      </div>
      <!-- <el-dialog :visible.sync="dialogVisible" width="30%">
        <vue-core-video-player
          :src="video_url"
        ></vue-core-video-player>
      </el-dialog> -->
    </div>
    <div 
    style="width: 100%;height: 50px;"
    v-if="bennrtList.length === 0"></div>
  </div>
  
</template>
<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex";
import Swiper from "swiper";
export default {
  name: "top-banner",
  components: {},
  props: {
    banner: {
      type: String,
      default:
        "https://guchengwan.oss-cn-beijing.aliyuncs.com/image/6mrst7b6af00000.jpg",
    },
    height: {
      type: Number,
      default: 378,
    },
  },
  watch: {
    bennrtList() {
      const ts = this;
      ts.$nextTick(() => {});
    },
  },
  computed: {
    ...mapGetters(["bennrtList", "secondMenu"]),
  },
  data() {
    return {
      video_url: "",
      bannerIndex: "",
      banner_value: this.banner,
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    imgClick(val) {
      if (val.material_type == "1") {
        if (val.jump_type == "1") {
          // 官网内页面路径
          this.$router.push({
            name: val.page,
            // query:{}
          });
        }
        if (val.jump_type == "2") {
          // 外链
          window.open(val.url, "_blank");
        }
      } else {
        this.video_url = val.video_url
        this.dialogVisible = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.font_60 {
  font-size: 60px;
  white-space: nowrap;
}
.font_16 {
  font-size: 16px;
  white-space: nowrap;
  letter-spacing: 6px;
  background-clip: text;
  -webkit-background-clip: text;
}
.video_container {
  width: 100%;
}
.top_swippe {
  position: relative;
}
.bg_img {
  height: 100%;
  width: 100%;
  background-size: cover !important;
  position: relative;
  background-position: center !important;
}
.banner {
  width: 100%;
  height: 561px;
  background: url("https://guchengwan.oss-cn-beijing.aliyuncs.com/image/6mrst7b6af00000.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .swiper-button-prev,
  .swiper-button-next {
    position: initial !important;
    width: 40px;
    height: 40px;
    display: block;
    &::after {
      display: none;
    }
  }
  .swiper-button-prev {
    background: url("../assets/banner-left.png") no-repeat;
    background-size: cover;
    left: 60px;
    opacity: 1 !important;
  }
  .swiper-button-next {
    background: url("../assets/banner-right.png") no-repeat;
    background-size: cover;
    right: 60px;
    opacity: 1 !important;
  }
}

.zdy {
  position: absolute;
  right: 60px;

  bottom: 30px;
  z-index: 99;
}
Ï .default-item {
  margin: 0 5px;
  position: relative;
  bottom: 7.5px;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-image: url("../assets/default-banner-icon.png");
}

.active-banner-item {
  background-image: url("../assets/active-banner-icon.png") !important;
}
.banner_word_bg {
  z-index: 10;
  position: absolute;
  top: calc(50% - 88px);
  left: calc(50% - 325px);
  background: url("../assets/body/header_word_bg.png") no-repeat;
  background-size: cover;
  width: 650px;
  height: 166px;
  color: #fff;
}
</style>