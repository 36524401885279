<template>
	<div class="pagination-box">
		<el-pagination  
		background
		prev-text="上一页"
		next-text="下一页"
		layout="total, prev, pager, next, jumper" 
		:total="parseInt(pagination.total)" 
		:page-size="parseInt(pagination.pagesize)" 
		:current-page="parseInt(pagination.page)" 
		:page-count="parseInt(pagination.pagecount)" 
		@current-change="pageChange" >
		</el-pagination>
	</div>  
</template>

<script>
	export default {
		name: "pagination-box",
		props: ['pagination'],
		data() {
			return {
				
			}
		},
		methods: {
      //页面点击跳转
      pageChange(page){
        window.scrollTo(0, 0);//跳转时页面置顶
        //val为跳转的页数
        this.$emit('pageChange',page)
      }
		}
	};
</script>
<style >
	
.pagination-box{
  text-align: center;
  margin-top: 100px;
}
.el-pagination.is-background .btn-prev,.el-pagination.is-background .btn-next {
	background-color: #E1D2B6;
	color: #7B602C;
	padding: 1px 14px;
}
.el-pagination.is-background .el-pager li {
	background-color: #FFFFFF;
	border: 1px #E1D2B6 solid;
}
.el-pager li:hover,.el-pagination.is-background .el-pager li:not(.disabled):hover {
	color: #7B602C;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #E1D2B6;
	color: #7B602C;
}
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev:disabled{
    color: #C0C4CC;
	background-color: #f4f4f5;
}
</style>
