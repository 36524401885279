<!-- 面包屑 -->
<template>
  <div class="breadcrumb flex ac">
    <img src="@/assets/body/nav_logo.png" alt="" class="nav_logo" />
    <span>{{ $t("aboutWe.currentLocation") }}：</span>
    {{ nowLocation }}
  </div>
</template>
<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex";
export default {
  name: "my-breadcrumb",
  props: {},
  computed: {
    ...mapGetters(["nowLocation"]),
  },
  async mounted() {
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  border-bottom: 1px solid #e3e3e3;
  padding: 31px 0 34px;
  gap:14px;
}
.nav_logo {
  height: 23px;
  width: 50px;
}
</style>